@tailwind base;
@tailwind components;
@tailwind utilities;

/* Color variables */
@layer base {
  :root {
    --tissueshare-teal: #209498;
  }
}

body {
  margin: 0;
  font-family: "SF-Pro-Rounded", "Shippori-Mincho-B1", "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html {
  overscroll-behavior-y: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "SF-Pro-Rounded";
  src: url("../static/font/SF-Pro-Rounded-Ultralight.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "SF-Pro-Rounded";
  src: url("../static/font/SF-Pro-Rounded-Light.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "SF-Pro-Rounded";
  src: url("../static/font/SF-Pro-Rounded-Thin.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "SF-Pro-Rounded";
  src: url("../static/font/SF-Pro-Rounded-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SF-Pro-Rounded";
  src: url("../static/font/SF-Pro-Rounded-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "SF-Pro-Rounded";
  src: url("../static/font/SF-Pro-Rounded-Semibold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "SF-Pro-Rounded";
  src: url("../static/font/SF-Pro-Rounded-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "SF-Pro-Rounded";
  src: url("../static/font/SF-Pro-Rounded-Black.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "SF-Pro-Rounded";
  src: url("../static/font/SF-Pro-Rounded-Heavy.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Shippori-Mincho-B1";
  src: url(../static/font/ShipporiMinchoB1-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Shippori-Mincho-B1";
  src: url(../static/font/ShipporiMinchoB1-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Shippori-Mincho-B1";
  src: url(../static/font/ShipporiMinchoB1-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Shippori-Mincho-B1";
  src: url(../static/font/ShipporiMinchoB1-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Shippori-Mincho-B1";
  src: url(../static/font/ShipporiMinchoB1-ExtraBold.ttf) format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

.h-screen-hack {
  height: calc(100vh - 64px);
}
@media (min-width: 600px) {
  .h-screen-hack {
    height: calc(100vh - 64px);
  }
}

@media (min-width: 0px) {
  @media (orientation: landscape) {
    .h-screen-hack {
      height: calc(100vh - 48px);
    }
  }
}

.table-row:nth-child(even) {
  background-color: #fafafa; /* Light grey */
}

.table-row:nth-child(odd) {
  background-color: #ffffff; /* White */
}

/* Radio buttons */
input.peer + label .radio-border,
.radio-ring {
  border-color: black;
}
input.peer:checked + label .radio-border,
.radio-ring {
  border-color: var(--tissueshare-teal);
}
input.peer:checked + label .radio-button,
.radio-dot {
  background-color: var(--tissueshare-teal);
}
input.peer:checked + label .radio-text {
  color: var(--tissueshare-teal);
}

input:disabled {
  color: #999;
  border-color: #ccc;
  cursor: not-allowed;
  opacity: 0.6;
}

textarea:disabled,
select:disabled {
  background-color: #f0f0f0;
  color: #999;
  border-color: #ccc;
  cursor: not-allowed;
  opacity: 0.6;
}

* {
  /* For debugging */
  /* outline: 1px solid blue !important; */
  box-sizing: border-box;
}

img {
  max-width: unset;
}
